import React, { useState } from 'react'
// import { Link } from 'gatsby'
import 'leaflet/dist/leaflet.css'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { heartIcon } from '../components/customIcons'
import { ringsIcon } from '../components/customIcons'
import { oarsIcon } from '../components/customIcons'
// import Layout from '../components/layout'
// import SEO from '../components/seo'
import '../styles/main.scss'
import Button from '@material-ui/core/Button'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import PhotoCameraRoundedIcon from '@material-ui/icons/PhotoCameraRounded'
import LibraryMusicRoundedIcon from '@material-ui/icons/LibraryMusicRounded'
import IconButton from '@material-ui/core/IconButton'
// import DeleteIcon from '@material-ui/icons/Clear'
// import AddIcon from '@material-ui/icons/Add'
import { StaticImage } from 'gatsby-plugin-image'

const location_Center = [47.68209942028788, 11.188467852838494]
const location_SV = [47.67081, 11.20295]
const location_BH = [47.68691, 11.18016]
const location_WO = [47.68651976719784, 11.158479962074225]

const renderLoginForm = (
  login,
  usernameVal,
  setUsernameVal,
  passwordVal,
  setPasswordVal,
  loginErrorMsg,
) => (
  <div className='loginpage'>
    <StaticImage
      className='LooPLogo'
      src='../images/LooP-white.svg'
      alt='The LooP Logo'
    />
    <form
      className='loginform'
      onSubmit={e => {
        e.preventDefault()
        login(usernameVal, passwordVal)
      }}
    >
      <input
        className='logincontent'
        type='username'
        name='username'
        id='username'
        required
        placeholder='username'
        value={usernameVal}
        onChange={e => setUsernameVal(e.target.value)}
      ></input>
      <br></br>

      <input
        className='logincontent'
        type='password'
        id='user-pw'
        name='user-pw'
        required
        placeholder='password'
        value={passwordVal}
        onChange={e => setPasswordVal(e.target.value)}
      ></input>

      {loginErrorMsg && (
        <p style={{ color: 'red', margin: 0, padding: 0 }}>{loginErrorMsg}</p>
      )}

      {!loginErrorMsg && <br></br>}
      <Button
        variant='outlined'
        color='primary'
        className='logincontent'
        type='submit'
        style={{ width: '100%' }}
      >
        enter the LooP
      </Button>
    </form>
  </div>
)

const renderPageContent = (
  logout,
  // bigOnesCount,
  // setBigOnesCount,
  // littleOnesCount,
  // setLittleOnesCount,
) => (
  <div>
    <div className='hero'>
      <StaticImage
        className='LooPLogo'
        src='../images/LooP-white.svg'
        alt='The LooP Logo'
      />
      <h2>forever and ever</h2>
    </div>
    <div className='whiteDiv'>
      <div className='divContent'>
        <h1>Hurrah, wir haben geheiratet!</h1> <br></br>
        <p className='box1'>
          Und ihr wart auch dabei,<br></br>
          <br></br>
          Wir bedanken uns für unser rauschendes Fest bei bestem Wetter und
          bester Stimmung. Wir haben jede Sekunde genossen, uns über jede:n
          Einzelne:n von euch gefreut. Vielen Dank auch für die tollen
          Geschenke, die Unterstützung und die Bilder - die wir euch natürlich
          nicht vorenthalten wollen.
          <br></br>
          <br></br>Wir lieben euch!<br></br>
          <br></br>Liebe Grüße, Larissa und Paul
        </p>
      </div>
    </div>

    <div className='roseDiv'>
      <div className='divContent'>
        <h1>Bilder</h1>
        <p className='sub-p'>
          Wir haben einen Riesenspaß und immer noch ein paar Freudentränen in
          den Augen beim Betrachten der Bilder, hoffentlich geht es euch auch
          so.<br></br>Hier findet ihr Links zu zwei Bildergallerien. Einmal die
          Bilder unserer Fotografen und einmal das best-of der Handy- und
          Kleinkamera-Bilder von euch, unseren lieben Gästen. Viel Spaß damit!
        </p>

        <div className='two'>
          <div className='box3'>
            <Button
              variant='outlined'
              size='large'
              startIcon={<PhotoCameraRoundedIcon />}
              href='https://photos.app.goo.gl/PYN4VS3wtpiiNPyQ9'
            >
              Bilder Fotografen
            </Button>
          </div>
          <div className='box3'>
            <Button
              variant='outlined'
              size='large'
              startIcon={<PhotoCameraRoundedIcon />}
              href='https://photos.app.goo.gl/CH2yKkv9fHCmLDqx8'
            >
              Bilder Gäste
            </Button>
          </div>
        </div>
        <br></br>
      </div>
    </div>

    <div>
      <StaticImage
        className='LooPWebPic'
        // src="../images/TitleImage-Tragerl.jpg"
        src='../images/LooP-web-pic-new.jpg'
        // height={600}
        alt='The LooP Pic'
        style={{ minWidth: '800px', verticalAlign: 'center' }}
      />
    </div>

    <div className='blueDiv'>
      <div className='footer'>
        <p>
          <br></br>
          <br></br>
          solltet ihr noch mehr Bilder oder sogar Videos haben - schreibt uns
          und immer gerne her damit!<br></br>(bei Bedarf auch mit dem
          disclaimer, sie bitte nicht zu teilen:)
        </p>
        <div className='divContent'>
          <Button
            variant='outlined'
            size='large'
            startIcon={<SendRoundedIcon />}
            href='mailto:icke@cometotheloop.de?body=My custom mail body'
          >
            Kontakt
          </Button>
          <br></br>
          <br></br>
          <div className='two'>
            <Button variant='outlined' onClick={() => logout()}>
              ausloggen
            </Button>
          </div>
        </div>
        <div
          style={{
            width: 200,
            margin: 50,
          }}
        >
          <StaticImage src='../images/LooP.svg' alt='Loop Logo'></StaticImage>
        </div>
      </div>
    </div>
  </div>
)

const IndexPage: React.FC = () => {
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false)
  // const [bigOnesCount, setBigOnesCount] = useState(1)
  // const [littleOnesCount, setLittleOnesCount] = useState(0)

  const [usernameVal, setUsernameVal] = useState('')
  const [passwordVal, setPasswordVal] = useState('')
  const [loginErrorMsg, setLoginErrorMsg] = useState('')

  const login = (username, password) => {
    // TODO: do the auth magic
    if (username === 'LooP' && password === 'letmein') {
      setLoginErrorMsg(null)
      setUserIsLoggedIn(true)
    } else {
      setLoginErrorMsg('Wroooooooooong!')
    }
  }

  const logout = () => {
    setUserIsLoggedIn(false)
  }

  return userIsLoggedIn
    ? renderPageContent(
        logout,
        // bigOnesCount,
        // setBigOnesCount,
        // littleOnesCount,
        // setLittleOnesCount,
      )
    : renderLoginForm(
        login,
        usernameVal,
        setUsernameVal,
        passwordVal,
        setPasswordVal,
        loginErrorMsg,
      )
}

export default IndexPage
